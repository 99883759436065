/* Sticky Ask Me container */
.sticky-ask-me {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999; /* Ensure it stays on top */
}

/* Alumni Guidance button */
.btn-ask-me {
  background-color: rgba(202, 107, 107, 0.863); /* Primary color for the button */
  color: white;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* Icon inside the button */
.btn-ask-me i {
  margin-right: 8px;
  font-size: 20px; /* Adjust the size of the icon */
  background-image: url("../../../public/assets/images/ask-me.jpg"); /* Replace with the correct path to your icon */
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.btn-ask-me span {
  margin-left: 8px;
}
