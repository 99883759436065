body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.progress-bar {
  background-color: #5cae48 !important;
}
.progress .join-progress .progress-bar {
  background-color: #26a7df !important;
}
.bg-darker-blue {
  background-color: #0b3454 !important;
}
.bg-dark-orange {
  background-color: #f05a2a !important;
}

.input__checked{
  background-color: #5cae48 !important;
  border-color: #5cae48 !important;
  --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e) !important;
}