/* ai chat */
#root {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: #f3f3f3;
}

.alumni_chat_block {
  background: #f2f9ff;
  padding: 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 565px;
}
.alumni_chat_to {
  padding: 30px 30px 0;
}
.alumni_chat_to label {
  font-weight: 500;
  font-size: 18px;
}
/* .sent_too_wrap .sent_too {background: #FFFFFF;border: 1px solid #0B345459;border-radius: 25px;opacity: 1;padding: 0 18px;height: 35px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;} */
.sent_too_wrap .sent_too {
  background: #ffffff;
  border: 1px solid #0b345459;
  border-radius: 25px;
  opacity: 1;
  padding: 0 18px;
  height: 35px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.count-unread {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #5cae48;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
.blue-block {
  background: transparent linear-gradient(104deg, #0062af 0%, #175381 100%) 0% 0% no-repeat padding-box;
  padding: 24px 30px;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  position: relative;
}
.blue-block:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.blue-block p:last-child {
  margin: 0;
}
.blue-block ol {
  padding: 0 0 0 21px;
}
.blue-block ol:last-child {
  margin: 0;
}
.justify-content-end .blue-block {
  margin-right: 8px;
  border-radius: 6px 6px 0px 6px;
  -webkit-border-radius: 6px 6px 0px 6px;
  -moz-border-radius: 6px 6px 0px 6px;
  -ms-border-radius: 6px 6px 0px 6px;
  -o-border-radius: 6px 6px 0px 6px;
}
.justify-content-end .blue-block:after {
  right: -8px;
  bottom: 0px;
  border-left: 5px solid #175381;
  border-bottom: 5px solid #175381;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
}
.justify-content-start .blue-block {
  margin-left: 8px;
  border-radius: 6px 6px 6px 0px;
  -webkit-border-radius: 6px 6px 6px 0px;
  -moz-border-radius: 6px 6px 6px 0px;
  -ms-border-radius: 6px 6px 6px 0px;
  -o-border-radius: 6px 6px 6px 0px;
}
.justify-content-start .blue-block:after {
  left: -8px;
  bottom: 0px;
  border-left: 5px solid transparent;
  border-bottom: 5px solid #0161ac;
  border-top: 5px solid transparent;
  border-right: 5px solid #0161ad;
}
.alumni_chat_conversation_send .form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0b345459;
  border-radius: 10px;
  opacity: 1;
}
.alumni_chat_conversation_block {
  max-height: 395px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 395px;
  padding: 0 30px;
}
.alumni_chat_conversation_send {
  padding: 0px 30px 30px 30px;
}
.get_started {
  text-align: center;
}
.get_started .get_started_like {
  width: 54px;
  height: 62px;
  margin-bottom: 20px;
}
.get_started h3 {
  font-size: 28px;
  font-weight: 600;
}

.liteblue-border-block {
  background: #fff;
  padding: 24px 30px;
  color: #000;
  font-size: 18px;
  line-height: 22px;
  position: relative;
  border: 1px solid #afdafb;
}
.liteblue-border-block::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #fff;
}
.justify-content-end .liteblue-border-block {
  margin-right: 8px;
  border-radius: 6px 6px 0px 6px;
  -webkit-border-radius: 6px 6px 0px 6px;
  -moz-border-radius: 6px 6px 0px 6px;
  -ms-border-radius: 6px 6px 0px 6px;
  -o-border-radius: 6px 6px 0px 6px;
}
.justify-content-end .liteblue-border-block::after {
  right: -8px;
  bottom: -1px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #afdafb;
  border-top: 1px solid #fff;
  border-right: 1px solid #afdafb;
  transform: skew(44deg);
  -webkit-transform: skew(44deg);
  -moz-transform: skew(44deg);
  -ms-transform: skew(44deg);
  -o-transform: skew(44deg);
}
.justify-content-start .liteblue-border-block {
  margin-left: 8px;
  border-radius: 6px 6px 6px 0px;
  -webkit-border-radius: 6px 6px 6px 0px;
  -moz-border-radius: 6px 6px 6px 0px;
  -ms-border-radius: 6px 6px 6px 0px;
  -o-border-radius: 6px 6px 6px 0px;
}
.justify-content-start .liteblue-border-block::after {
  left: -8px;
  bottom: -1px;
  border-left: 1px solid #afdafb;
  border-bottom: 1px solid #afdafb;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: skew(44deg);
  -webkit-transform: skew(-44deg);
  -moz-transform: skew(44deg);
  -ms-transform: skew(44deg);
  -o-transform: skew(44deg);
}
.liteblue-border-block p:last-child {
  margin: 0;
}
.liteblue-border-block ol {
  padding: 0 0 0 21px;
}
.liteblue-border-block ol:last-child {
  margin: 0;
}

.liteblue-border-block ul {
  padding: 0 0 0 21px;
}
.liteblue-border-block ul:last-child {
  margin: 0;
}

.get_started_prompt a {
  display: inline-block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0b345459;
  border-radius: 25px;
  opacity: 1;
  padding: 8px 30px;
  text-decoration: none;
  font-size: 18px;
  color: #0b3454;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: ;
  -moz-border-radius: ;
  -ms-border-radius: ;
  -o-border-radius: ;
}
.get_started_prompt a:hover {
  background: #6d879c;
  color: #fff;
}

.btn-chat-ai {
  background: #5cae48;
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.btn-chat-ai i {
  margin-right: 7px;
}
.btn-chat-ai:hover {
  color: #fff;
  background-color: #0b3454;
}

.ai-chat-wrap {
  height: 100vh;
  padding: 0;
}
.ai-chat-wrap .ai-chat {
  height: 100%;
  max-width: 1140px;
  width: 96%;
  margin: auto;
  border-radius: 10px;
}
.ai-chat-wrap .ai-chat .alumni_chat_block {
  background: none;
  min-height: inherit;
}
.ai-chat-wrap .ai-chat .alumni_chat_block_header {
  padding: 20px 0;
  background: transparent;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #efefef;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
  position: relative;
  overflow: hidden;
}
.ai-chat-wrap .ai-chat .alumni_chat_conversation_send {
  padding: 30px 0;
  background: transparent;
  border-radius: 0px 0 10px 10px;
  border-top: 1px solid #efefef;
  -webkit-border-radius: 0px 0 10px 10px;
  -moz-border-radius: 0px 0 10px 10px;
  -ms-border-radius: 0px 0 10px 10px;
  -o-border-radius: 0px 0 10px 10px;
}
.alumni_chat_block_header i.icon-chat-invert {
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 140px;
  width: 140px;
  color: #e1e1e1;
}
.ai-img {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: end;
  background: #ededed;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
i.icon-compose-mail {
  background: url(https://www.univariety.xyz/prototype/school_dashboard/images/message-compose.svg) no-repeat 0 0;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0;
  padding: 0;
}
i.icon-mail-send {
  background: url(https://www.univariety.xyz/prototype/school_dashboard/images/send_alumni_profile_red.svg) no-repeat 0 0;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0;
  padding: 0;
}
.ai-chat .get_started {
  flex-direction: column-reverse;
  overflow: auto;
}
.coversation-block {
  flex-direction: column-reverse;
  overflow: auto;
  flex: 1;
}
.ai-chat .icon-turn-off {
  width: 15px;
  height: 15px;
  border-left: 2px solid #555;
  border-bottom: 2px solid #555;
  border-right: 2px solid #555;
  border-radius: 50%;
  border-top: 3px solid transparent;
  position: relative;
  top: -20px;
  right: 5px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.ai-chat .icon-turn-off:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  width: 2px;
  height: 8px;
  bottom: 5px;
  background: #555;
  margin: auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.ai-chat .icon-turn-off:hover {
  border-left: 2px solid #f0555f;
  border-bottom: 2px solid #f0555f;
  border-right: 2px solid #f0555f;
}
.ai-chat .icon-turn-off:hover:before {
  background: #f0555f;
}

.justify-content-end .date {
  text-align: right;
}
.user-chat .ai-img {
  width: 60px;
  height: 60px;
  font-size: 48px;
  color: #979797;
}

.rating-emoji label {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  background-color: #000;
  border: 1px solid #af8d09;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.rating-emoji .sad {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffd21f' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-2.715 5.933a.5.5 0 0 1-.183-.683A4.5 4.5 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.5 3.5 0 0 0 8 10.5a3.5 3.5 0 0 0-3.032 1.75.5.5 0 0 1-.683.183M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");
}
input[name="rating"]:checked + .sad {
  background-color: #fff;
  border: 1px solid #ae000b;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ae000b' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-2.715 5.933a.5.5 0 0 1-.183-.683A4.5 4.5 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.5 3.5 0 0 0 8 10.5a3.5 3.5 0 0 0-3.032 1.75.5.5 0 0 1-.683.183M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");
}

.rating-emoji .neutral {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffd21f' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-3 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");
}
input[name="rating"]:checked + .neutral {
  background-color: #fff;
  border: 1px solid #f1a200;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23f1a200' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-3 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");
}

.rating-emoji .happy {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffd21f' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");
}
input[name="rating"]:checked + .happy {
  background-color: #fff;
  border: 1px solid #5bae47;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%235bae47' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");
}

ul.feed-back-list {
  padding: 5px 0 0;
  margin: 0;
  list-style: none;
  max-width: 500px;
}
ul.feed-back-list li:not(:last-child) {
  margin-bottom: 10px;
}
ul.feed-back-list li {
  background: #f7f7f7;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding: 12px 25px;
  border: 1px solid #ccc;
  line-height: 18px;
  font-size: 16px;
}

.justify-content-end .blue-block {
  margin-right: 8px;
  border-radius: 6px 6px 0px 6px;
  -webkit-border-radius: 6px 6px 0px 6px;
  -moz-border-radius: 6px 6px 0px 6px;
  -ms-border-radius: 6px 6px 0px 6px;
  -o-border-radius: 6px 6px 0px 6px;
}

@media (max-width: 767px) {
  .ai-chat-wrap {
    padding: 0;
  }
  .ai-chat-wrap .ai-chat {
    width: 100%;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .ai-chat-wrap .ai-chat .alumni_chat_block_header {
    padding: 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .ai-img {
    width: 55px;
    height: 55px;
  }
  .ai-chat-wrap .ai-chat .alumni_chat_conversation_send {
    padding: 30px 15px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .get_started_prompt a {
    padding: 6px 20px;
    font-size: 16px;
  }
  ul.feed-back-list li {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px;
  }
}
